import "styles/pages/page-cyber-solutions.scss";

import React from "react";

import Seo from "components/Seo";
import Layout from "components/Layout";
import SectionHeading from "components/SectionHeading";
import CallToAction from "components/CallToAction";
import TitleWithLine from "../components/TitleWithLine";
import Faq from "../components/Faq";
import WhiteTile from "../components/WhiteTile";

const why_icon_render = (icon) => {
  switch (icon) {
    case "hand":
      return require("assets/videos/icons/price.mp4").default;
    case "raport":
      return require("assets/videos/icons/report.mp4").default;
    case "arrows":
      return require("assets/videos/icons/detection.mp4").default;
    case "circle":
      return require("assets/videos/icons/retesting.mp4").default;
    case "person":
      return require("assets/videos/icons/manager.mp4").default;
    default:
      return null;
  }
};

const CyberSolutions = ({ pageContext }) => {
  const cyber_solutions = pageContext.pageContent;

  return (
    <Layout logo_variant="green">
      <Seo
        title={
          pageContext.seo.title ? pageContext.seo.title : pageContext.title
        }
        description={pageContext.seo?.description}
      />
      <div className="page-cyber-solutions">
        <section className="page-cyber-solutions__header">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-11">
                <div className="home-hero__text-wrapper">
                  <h1 className="home-hero__title">
                    {cyber_solutions?.pageHeaderText}
                  </h1>
                  <div className="home-hero__text" />
                  <a href="#section-info" className="btn btn--green btn--down">
                    See more
                  </a>
                </div>
              </div>
            </div>
          </div>
          <video autoPlay={true} muted={true} loop={true} playsInline={true}>
            <source
              src={require("assets/videos/cyber-solutions-hero.mp4").default}
              type="video/mp4"
            />
          </video>
        </section>

        <section id="section-info" className="section-info">
          <div className="container">
            <SectionHeading
              className="section-info__title"
              subtitle={cyber_solutions?.firstText}
            />
          </div>
        </section>

        <section className="section-why">
          <div className="container">
            <SectionHeading
              className="section-why__title"
              title="Why choose Prodinity?"
            />
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-5 gy-5">
              {cyber_solutions?.whyItems?.map(({ icon, text }, index) => (
                <div key={index} className="col section-why__item">
                  <div className="section-why__item__icon">
                    {/* <img src={why_icon_render(icon)} alt="" /> */}
                    <video
                      autoPlay={true}
                      muted={true}
                      loop={true}
                      playsInline={true}
                    >
                      <source src={why_icon_render(icon)} type="video/mp4" />
                    </video>
                  </div>
                  <span>{text}</span>
                </div>
              ))}
            </div>
            <div
              className="section-why__text"
              dangerouslySetInnerHTML={{ __html: cyber_solutions?.whyText }}
            />
          </div>
        </section>

        <section className="section-how-test-work">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 section-how-test-work__image">
                <img
                  src={cyber_solutions?.howWorkImage?.sourceUrl}
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div className="col-lg-6">
                <div className="section-how-test-work__inner">
                  <TitleWithLine variantY="bottom" color="green">
                    {cyber_solutions?.howWorkTitle}
                  </TitleWithLine>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: cyber_solutions?.howWorkText,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-our-process">
          <div className="container">
            <SectionHeading
              className="section-our-process__title"
              title="Our Process"
            />
            <div className="row row-cols-1 row-cols-lg-4">
              {cyber_solutions?.ourProcessItems?.map(
                ({ title, text }, index) => (
                  <div
                    key={index}
                    className="col g-0 section-our-process__item"
                  >
                    <div className="section-our-process__item__number__wrapper">
                      <div
                        className="section-our-process__item__number"
                        // style={{ backgroundColor: color }}
                      >
                        <span>{index + 1}</span>
                      </div>
                    </div>
                    <div className="section-our-process__item__text">
                      <span>{title}</span>
                      <p>{text}</p>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </section>

        <section className="section-our-report">
          <div className="container">
            <SectionHeading
              className="section-our-report__title"
              title="What our report consists of"
              color="white"
            />
            <div className="row">
              {cyber_solutions?.whatOurReportItems?.map(
                ({ title, text }, index) => (
                  <div
                    key={index}
                    className="col-lg-4 section-our-report__item"
                  >
                    <span>{title}</span>
                    <p>{text}</p>
                  </div>
                )
              )}
            </div>
          </div>
        </section>

        <section className="section-our-credentials">
          <div className="container">
            <SectionHeading
              className="section-our-report__title"
              title="Our Credentials"
              color="white"
            />
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="section-our-credentials__inner"
                  dangerouslySetInnerHTML={{
                    __html: cyber_solutions?.ourCredentialsText,
                  }}
                ></div>
              </div>
              <div className="col-lg-6">
                <div className="section-our-credentials__image">
                  <img
                    src={cyber_solutions?.ourCredentialsImage?.sourceUrl}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-cyber-solutions-faq">
          <div className="container">
            <h2>FAQ</h2>
            <Faq items={cyber_solutions?.faqItems} />
          </div>
        </section>

        <section className="section-other-cyber-solutions">
          <div className="container">
            <SectionHeading
              className="section-our-report__title"
              title={cyber_solutions?.otherTitle}
              subtitle={cyber_solutions?.otherSubTitle}
            />
            <div className="row">
              {cyber_solutions?.othersItems?.map(({ text }, index) => {
                return (
                  <div className="col-lg-3" key={index}>
                    <WhiteTile border="bordered">{text}</WhiteTile>
                  </div>
                );
              })}
            </div>
          </div>
          <video autoPlay={true} muted={true} loop={true} playsInline={true}>
            <source
              src={require("assets/videos/pattern-green.mp4").default}
              type="video/mp4"
            />
          </video>
        </section>
      </div>
      <CallToAction color="green" />
    </Layout>
  );
};

export default CyberSolutions;
