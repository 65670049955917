import "./styles.scss";

import React from "react";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import theme from "../../theme";

import contactPinkBg from "assets/images/contact-bg-pink.png";
import contactGreenBg from "assets/images/contact-bg-green.png";
import contactBlueBg from "assets/images/contact-bg-blue.png";

const ContactFormWrapper = styled.section`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 90px 0;

  ${theme.screens.smScreen} {
    text-align: center;
  }
`;

const PersonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  ${theme.screens.lgScreen} {
    margin-bottom: 80px;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const Title = styled.h2`
  color: #fff;
  font-size: 36px;
  margin-bottom: 12px;

  ${theme.screens.lgScreen} {
    font-size: 32px;
  }

  ${theme.screens.lgScreen} {
    font-size: 28px;
    text-align: center;
  }
`;

const Description = styled.div`
  color: #fff;
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 50px;

  ${theme.screens.lgScreen} {
    font-size: 16px;
    text-align: center;
  }

  br {
    ${theme.screens.xlScreen} {
      display: none;
    }
  }
`;

const Avatar = styled.img`
  border-radius: 50%;
  margin-right: 40px;
  width: 200px;
  height: 200px;
  object-fit: cover;

  ${theme.screens.lgScreen} {
    display: block;
    margin: 0 auto 15px;
  }
`;

const Job = styled.div`
  font-size: 14px;
  color: #fff;
  margin-bottom: 15px;
`;

const ContactDetail = styled.a`
  color: #fff;
  font-size: 16px;
  display: block;

  ${theme.mixins.onEvent} {
    color: #fff;
    text-decoration: underline;
  }
`;

const Socials = styled.div`
  display: flex;
  justify-items: flex-start;
  align-items: center;
  margin: 10px 0 20px;

  ${theme.screens.smScreen} {
    justify-content: center;
  }

  a {
    margin-right: 8px;
  }
`;

const SocialIcon = styled.img`
  transition: all 0.25s ease-in-out;
  ${theme.mixins.onEvent} {
    opacity: 0.5;
  }
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${theme.screens.lgScreen} {
    align-items: center;
    text-align: center;
  }
`;

const Button = styled.button`
  margin: 100px 0 47px;
  padding: 15px 40px;
  background-color: #fff;
  border-radius: 5px;
  border: none;
  box-shadow: none;
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  color: #006837;
  -webkit-box-shadow: 0px 5px 15px 0px rgba(193, 217, 49, 0.35);
  -moz-box-shadow: 0px 5px 15px 0px rgba(193, 217, 49, 0.35);
  box-shadow: 0px 5px 15px 0px rgba(193, 217, 49, 0.35);

  ${theme.screens.lgScreen} {
    margin-top: 20px;
  }
`;

const CallToAction = ({ color }) => {
  const current_person = {
    avatar: require("assets/images/jack-avatar.jpg").default,
    name: "Jack Viljoen",
    workplace: "Head of Marketing",
    phone: [
      {
        label: "+44 7731 736 843",
        href: "+44 7731 736 843",
      },
    ],
    email: "jviljoen@prodinity.com",
  };

  const contact_bg_render = () => {
    switch (color) {
      case "blue":
        return require("assets/videos/newsletter-blue-background.mp4").default;
      case "green":
        return require("assets/videos/newsletter-green-background.mp4").default;
      default:
        return require("assets/videos/newsletter-background.mp4").default;
    }
  };

  return (
    <div className="contact-form">
      <ContactFormWrapper>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="col-lg-10">
                <Title>Get In Touch</Title>
                <Description>
                  If you are interested in any of the above services, please
                  don’t hesitate to contact us at{" "}
                  <a
                    href="mailto:cyber@prodinity.com"
                    style={{ color: "#FCEE21" }}
                  >
                    cyber@prodinity.com
                  </a>
                  .
                </Description>
              </div>
              <PersonContainer>
                <Avatar src={current_person?.avatar} alt="" />
                <div>
                  <h4 style={{ color: "#fff" }}>{current_person?.name}</h4>
                  <Job>{current_person?.workplace}</Job>
                  {current_person?.phone?.map(({ label, href }, index) => (
                    <ContactDetail key={index} href={`tel:${href}`}>
                      {label}
                    </ContactDetail>
                  ))}
                  <ContactDetail href={`mailto:${current_person?.email}`}>
                    {current_person?.email}
                  </ContactDetail>
                  {!!current_person?.linkedin && (
                    <Socials>
                      <a
                        href={current_person?.linkedin}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <SocialIcon
                          src={
                            require("assets/icons/linkedin-white.svg").default
                          }
                          alt=""
                        />
                      </a>
                    </Socials>
                  )}
                </div>
              </PersonContainer>
            </div>
            <div className="col-lg-6">
              <div className="col">
                <Title>
                  Let me know if you are available this week for a quick chat.
                </Title>
              </div>
              <ActionContainer>
                <a
                  href="https://calendly.com/jviljoenprodinity/cyber-security-consultation"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button>Schedule a meeting</Button>
                </a>
                <Title>Looking forward to meeting you!</Title>
              </ActionContainer>
            </div>
          </div>
          <video autoPlay={true} muted={true} loop={true} playsInline={true}>
            <source src={contact_bg_render(color)} type="video/mp4" />
          </video>
        </div>
      </ContactFormWrapper>
    </div>
  );
};

export default ({ color }) => (
  <StaticQuery
    query={graphql`
      {
        data: allWpPage(
          filter: { template: { templateName: { eq: "Contact" } } }
        ) {
          nodes {
            contactPage {
              addresCol1
              addresCol2
              title1
              title2
              subtitle1
              subtitle2
              privacyPolicy
            }
          }
        }
      }
    `}
    render={(data) => <CallToAction {...data} color={color} />}
  />
);
