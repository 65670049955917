import React, { useState } from "react";
import styled from "styled-components";
import theme from "theme";
import DropDownIcon from "../../assets/icons/arrow_down_icon.svg";

const FaqMain = styled.div`
  background-color: #fff;
  box-shadow: ${theme.shadows.shadow};
  border-radius: 5px;
  padding: 35px;
  padding-left: 20px;
  padding-bottom: 36px;
  margin-top: 30px;
  position: relative;
  counter-increment: faq;
`;

const Title = styled.h3`
  font-size: 18px;
  margin-right: 10px;
  font-weight: 500;
  transition: all 0.25s ease-in-out;
  &:before {
    content: counter(faq) ". ";
  }
  ${theme.mixins.onEvent} {
    color: ${theme.colors.pink};
  }
`;

const Description = styled.p`
  font-size: 16px;
  padding-top: 25px;
  display: none;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Arrow = styled.img`
  transform: rotate(0);
  transition: all 0.25s;
  margin-left: 15px;
`;

const Item = styled.div`
  padding-bottom: 25px;
  cursor: pointer;
  ${({ active }) =>
    active &&
    `

    ${Description} {
        display: block;
    }

    ${Arrow} {
      transform: rotate(180deg);
    }
  `}

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

const Faq = ({ items, visibleOnStart, className }) => {
  const [openedQuestion, setOpenedQuestion] = useState(null);
  const openQuestion = (index) => {
    openedQuestion !== index ? setOpenedQuestion(index) : setOpenedQuestion(-1);
  };
  return Object.values(items).map((item, index) => {
    return (
      <FaqMain key={index}>
        <Item
          key={index}
          onClick={(e) => openQuestion(index)}
          active={openedQuestion === index}
        >
          <ContentContainer>
            <Title>{item.title}</Title>

            <Arrow src={DropDownIcon} />
          </ContentContainer>
          <Description dangerouslySetInnerHTML={{ __html: item.desc }} />
        </Item>
      </FaqMain>
    );
  });
};

export default Faq;
